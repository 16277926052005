<template>
  <form
    class="form"
    :class="{ mobile: props.isMobile }"
    @submit.prevent="handleSubmit"
  >
    <h2 v-if="!props.isMobile" class="title">
      {{ t('authorization.firstStepTitle') }}
    </h2>
    <StInput
      v-bind="email.componentBindings"
      :error="!!password.error"
      :label="t('authorization.emailLabel')"
      :placeholder="t('authorization.emailPlaceholder')"
      :size="props.isMobile ? 'm' : 'l'"
      autocomplete="email"
      type="email"
      data-t="email"
    />
    <div class="password">
      <StInputPassword
        v-bind="password.componentBindings"
        :label="t('authorization.passwordLabel')"
        :placeholder="t('authorization.passwordPlaceholder')"
        type="password"
        data-t="password"
        :error="!!password.error"
        :error-message="password.error"
        :size="props.isMobile ? 'm' : 'l'"
      />
      <StButton
        class="forgot-password"
        type="text-only"
        data-t="recovery-button"
        size="s"
        replace
        :to="{ query: { modal: 'recoverPassword', email: values.email } }"
        :label="t('authorization.forgotPassword')"
      >
      </StButton>
    </div>
    <div class="actions-buttons">
      <StButton
        submit
        data-t="next-step"
        class="submit-button"
        :label="t('authorization.firstStepButton')"
        :size="props.isMobile ? 'l' : 'xl'"
        :loading="requestStatus === 'pending'"
        :disabled="!isValid || isLoadingRecaptcha"
      />
      <div class="button-separator">
        <span class="line-separator" />
        <span>{{ t('registration.or') }}</span>
        <span class="line-separator" />
      </div>
      <div class="providers-auth">
        <StButton
          class="provider-button"
          type="gray"
          :label="t('registration.googleButtonShort')"
          :size="props.isMobile ? 'l' : 'xl'"
          icon="c-google"
          is-left-icon
          data-t="google-sign-in"
          @click="handleGoogleSignIn"
        />
        <StButton
          class="provider-button"
          type="gray"
          :label="t('registration.telegramButton')"
          :size="props.isMobile ? 'l' : 'xl'"
          icon="c-telegram"
          is-left-icon
          data-t="telegram-sign-in"
          :loading="isTgProcessing"
          @click="handleTelegramSignIn"
        />
      </div>
    </div>
    <div v-if="!props.isMobile" class="create-account">
      <span class="text"> {{ t('authorization.noAccount') }} </span>
      <StButton
        type="text-only"
        class="button"
        replace
        :to="{ query: { modal: 'register' } }"
        :label="t('authorization.createAccount')"
      />
    </div>
  </form>
</template>

<script setup lang="ts">
import { required, useForm, validEmail } from '@st/validate'
import { useSettingsStore } from '@st/core/stores/useSettingsStore'
import { useTelegramAuthWidget } from '@st/telegram/composables/useTelegramAuthWidget'
import { useTelegram } from '@st/telegram/composables/useTelegram'
import { useEmailLogin } from '../../../composables/useEmailLogin'
import { useGoogleRedirect } from '../../../composables/useGoogleRedirect'
import { useUserStore } from '../../../stores/useUserStore'

const props = withDefaults(
  defineProps<{
    isMobile: boolean
  }>(),
  {
    isMobile: false,
  },
)

const emit = defineEmits<{
  (e: 'success', payload: { email: string; password: string }): void
  (e: 'login'): void
}>()

const { t, locale } = useI18n()

const {
  fields: { email, password },
  isValid,
  values,
} = useForm({
  fieldsSchema: {
    email: {
      initialValue: '',
      validators: [
        {
          rule: required,
          triggerErrorDisplay: 'never',
        },
        {
          rule: validEmail,
          errorMessage: t('authorization.emailShouldBeValid'),
        },
      ],
    },
    password: {
      initialValue: '',
      validators: [
        {
          rule: required,
          triggerErrorDisplay: 'never',
        },
      ],
    },
  },
})

const { getToken, isLoadingRecaptcha, recaptchaQueryParam } = useRecaptcha()

const {
  execute: sendCode,
  error,
  status: requestStatus,
} = useStFetch('/auth/login/email/code/send', {
  method: 'post',
  body: values,
  immediate: false,
  watch: false,
  query: recaptchaQueryParam,
})

const { open } = useToast()

const { loginError, handleUserLogin } = useEmailLogin(values)
const { settings } = storeToRefs(useSettingsStore())
const isEnabledtwoFAOnLogin = computed(
  () => !!settings.value?.twoFAOnLoginEnabled,
)
async function handleSubmit() {
  if (isEnabledtwoFAOnLogin.value) {
    await getToken('/auth/login/email/code/send')
    await sendCode()
    if (!error.value) emit('success', values.value)
  } else {
    await handleUserLogin()
    if (!loginError.value) emit('login')
  }
}

const errorCodes = computed<Record<string, string>>(() => ({
  CONFIRMATION_CODE_RESEND_TIMEOUT: t(
    'authorization.errorMessages.confirmationCode',
  ),
  RECAPTCHA_IS_REQUIRED: t('authorization.errorMessages.recaptchaRequired'),
  RECAPTCHA_IS_INVALID: t('authorization.errorMessages.recaptchaInvalid'),
  VALIDATION_ERROR: t('authorization.errorMessages.validationError'),
  USER_INVALID_PASSWORD: t('authorization.errorMessages.invalidPassword'),
  AUTH_TIMEOUT_ERROR: t('authorization.errorMessages.timeoutError'),
  CONFIRMATION_CODE_RATE_LIMIT: t(
    'authorization.errorMessages.confirmationCodeRateLimit',
  ),
  RECAPTCHA_REQUEST_ERROR: t(
    'authorization.errorMessages.recaptchaRequestError',
  ),
  CONFIRMATION_CODE_REQUIRED: t(
    'authorization.errorMessages.somethingWentWrong',
  ),
}))

const errorMessage = computed(() => {
  const errorBody = error.value?.data || loginError.value?.data
  if (!errorBody) return undefined

  const { error: errorCode } = errorBody

  if (errorCode === 'AUTH_FORBIDDEN' && errorBody.data?.externalId) {
    return t('authorization.errorMessages.authForbidden', {
      userId: errorBody.data.externalId,
    })
  }

  return errorCodes.value[errorCode]
})

watch(
  errorMessage,
  (value) => {
    if (value) {
      password.setError(' ')

      open({
        label: value,
        type: 'negative',
      })
    }
  },
  { deep: true },
)

watch(
  () => email.value,
  () => password.setError(''),
)

const { handleGoogleRedirect } = useGoogleRedirect()
function handleGoogleSignIn() {
  handleGoogleRedirect({ language: locale.value })
}

const { auth, isTelegramMiniApp } = useTelegram()
const { handleTelegramAuth } = useTelegramAuthWidget()
const { tryToAuthenticate } = useUserStore()
const isTgProcessing = ref(false)
async function handleTelegramSignIn() {
  function authFn(data: any) {
    return isTelegramMiniApp.value ? auth(data) : handleTelegramAuth(data)
  }

  try {
    isTgProcessing.value = true
    await authFn({ language: locale.value })
    await tryToAuthenticate()
    emit('login')
  } catch {
    open({
      label: t('authorization.errorMessages.somethingWentWrong'),
      type: 'negative',
    })
  } finally {
    isTgProcessing.value = false
  }
}
</script>

<style scoped>
.title {
  margin: 0;
  font: var(--desktop-text-xl-semibold);
}

.form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--spacing-200);

  width: 100%;

  &.mobile {
    padding-bottom: var(--spacing-200);

    .submit-button {
      margin-top: auto;
    }
  }
}

.error-message {
  font: var(--desktop-text-sm-medium);
  color: var(--system-error);
}

.password {
  position: relative;
  margin-bottom: var(--spacing-100);
}

.forgot-password {
  cursor: pointer;

  position: absolute;
  top: 0;
  right: 0;

  padding-top: var(--spacing-050);

  font: var(--desktop-text-xs-medium);
  color: var(--palette-brand-500);

  background-color: transparent;
  border: 0;
}

.create-account {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: var(--spacing-050);

  .text {
    margin-right: var(--spacing-025);
    font: var(--desktop-text-xs-medium);
    color: var(--palette-light-1000);
    opacity: 0.48;
  }

  .button {
    cursor: pointer;

    font: var(--desktop-text-xs-semibold);
    color: var(--palette-brand-500);

    background-color: transparent;
    border: 0;
  }
}

.actions-buttons {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-125);
}

.button-separator {
  display: flex;
  gap: var(--spacing-250);
  align-items: center;
  justify-content: center;

  font: var(--desktop-text-xs-medium);
  color: var(--text-secondary);
  text-transform: lowercase;
}

.line-separator {
  flex-grow: 1;
  height: 0.5px;
  background: var(--border-primary);
}

.providers-auth {
  overflow: hidden;
  display: flex;
  gap: var(--spacing-100);
  align-items: center;
  justify-content: space-between;

  .provider-button {
    width: 100%;
    min-width: 0;
  }
}
</style>
